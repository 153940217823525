import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
@Component({
  selector: 'app-log-in-form',
  templateUrl: './log-in-form.component.html',
  styleUrls: ['./log-in-form.component.css']
})
export class LogInFormComponent implements OnInit {

  constructor(private _router: Router) {}
  contact : any;
  ngOnInit() {
 
    }
    login(){

      var phoneno = /^\d{10}$/;
  if((this.contact.match(phoneno))) {
        this._router.navigate(['/home']);
  } else{
    alert('Please Provide Valid Mobile Number');
  }

    
    }
}